import { type Role, Tier } from "@prisma/client";
import { isNil } from "lodash";

export type VendorPermission = (typeof VendorPermissions)[number];

export const VendorPermissions = ["can_be_contacted"] as const;

export const TierPermissions = [
  "show_good_for_tags",
  "show_positioning",
  "show_features",
  "show_pricing",
  "show_product_categories",
  "show_demos",
  "show_product_images",
  "show_brand_videos",
  "show_downloads",
  "show_custom_review_questions",
  "show_promo",
  "show_custom_banner_image",
  "contact_vendor",
  "schedule_demo",
  "respond_to_reviews",
] as const;
export type TierPermission = (typeof TierPermissions)[number];

const tier_1: TierPermission[] = [
  "show_positioning",
  "show_features",
  "show_pricing",
  "show_product_categories",
  "show_custom_banner_image",
  "respond_to_reviews",
  "contact_vendor",
];
const tier_2: TierPermission[] = [
  ...tier_1,
  "show_good_for_tags",
  "show_product_images",
  "show_brand_videos",
  "show_downloads",
];

const tier_3: TierPermission[] = [
  ...tier_2,
  "show_demos",
  "show_custom_review_questions",
  "show_promo",
  "schedule_demo",
];

export const TierPermissionMap: Record<Tier, TierPermission[]> = {
  free: [],
  tier_1,
  tier_2,
  tier_3,
};

export const UserPermissions = [
  "*", // All permissions
  "see_pricing",
  "see_demos",
  "see_product_images",
  "see_brand_videos",
  "see_downloads",
  "contact_vendor",
  "see_user_list",
  "manage_product_subscription",
  "manage_owner_users",
  "manage_users",
  "see_analytics",
] as const;
export type UserPermission = (typeof UserPermissions)[number];

export const UserRolePermissionMap: Record<Role, UserPermission[]> = {
  GOD_MODE: ["*"],
  RVI_SUPPORT: [],
  BUYER: [
    "see_pricing",
    "see_demos",
    "see_product_images",
    "see_brand_videos",
    "see_downloads",
    "contact_vendor",
  ],
  OWNER: [
    "see_pricing",
    "see_demos",
    "see_product_images",
    "see_brand_videos",
    "see_downloads",
    "contact_vendor",
    "see_user_list",
    "manage_product_subscription",
    "manage_owner_users",
    "manage_users",
    "see_analytics",
  ],
  ADMIN: [
    "see_pricing",
    "see_demos",
    "see_product_images",
    "see_brand_videos",
    "see_downloads",
    "contact_vendor",
    "see_user_list",
    "see_analytics",
    "manage_users",
  ],
  EDITOR: [
    "see_pricing",
    "see_demos",
    "see_product_images",
    "see_brand_videos",
    "see_downloads",
    "contact_vendor",
    "see_analytics",
  ],
};

export function userHasPermission(
  role: Role | undefined,
  permission: UserPermission
) {
  if (isNil(role)) return false;
  return UserRolePermissionMap[role].includes(permission);
}

export function tierHasPermission(
  tier: Tier | undefined,
  permission: TierPermission
) {
  if (isNil(tier)) return false;
  return TierPermissionMap[tier].includes(permission);
}

export function vendorHasPermission(
  vendor: {
    products: {
      subscriptions: {
        stripe_price: { product: { tier: Tier } };
      }[];
    }[];
    vendor_integrations: any[];
  },
  permission: VendorPermission
) {
  if (permission === "can_be_contacted") {
    return vendor.products.some(product =>
      product.subscriptions.some(
        sub =>
          sub.stripe_price.product.tier !== "free" &&
          [Tier.tier_1, Tier.tier_2, Tier.tier_3].includes(
            sub.stripe_price.product.tier
          )
      )
    );
  }
  return false;
}
